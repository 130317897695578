import Section from "../Section"
import styles from "./Accordion.module.scss"
import TextContent from "../TextContent"
import * as RadixAccordion from "@radix-ui/react-accordion"
import { forwardRef } from "react"
import { ChevronDown } from "react-feather"

export const Accordion = ({ data }) => {
  // console.log("Accordion", data)

  return (
    <Section
      className={styles.Accordion}
      extraSpacing={false}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <div className={styles.inner}>
        <TextContent
          className={styles.textContent}
          headlineClassName={styles.headline}
          sublineClassName={styles.subline}
          toplineClassName={styles.topline}
          content={data?.textcontent}
        />

        {data?.entries?.length ? (
          <RadixAccordion.Root
            type="multiple"
            collapsible={"collapsible"}
            className={styles.accordion}
          >
            {data?.entries?.map((entry, i) => (
              <RadixAccordion.Item
                value={`item-${i + 1}`}
                key={`item-${i + 1}`}
              >
                <RadixAccordionTrigger className={styles.trigger}>
                  {entry.headline}
                </RadixAccordionTrigger>
                <RadixAccordionContent className={styles.content}>
                  <TextContent content={entry.textcontent} />
                </RadixAccordionContent>
              </RadixAccordion.Item>
            ))}
          </RadixAccordion.Root>
        ) : null}
      </div>
    </Section>
  )
}

const RadixAccordionTrigger = forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <RadixAccordion.Header>
      <RadixAccordion.Trigger
        className={className}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDown aria-hidden />
      </RadixAccordion.Trigger>
    </RadixAccordion.Header>
  ),
)
RadixAccordionTrigger.displayName = "RadixAccordionTrigger"

const RadixAccordionContent = forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <RadixAccordion.Content className={className} {...props} ref={forwardedRef}>
      <div>{children}</div>
    </RadixAccordion.Content>
  ),
)
RadixAccordionContent.displayName = "RadixAccordionContent"

export default Accordion
