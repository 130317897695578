import styles from "./GoogleMap.module.scss"
import React, { useEffect, useRef, useState } from "react"
import useTranslation from "next-translate/useTranslation"
import getConfig from "next/config"
import cx from "classnames"
import { useDispatch, useSelector } from "react-redux"
import {
  searchJobs,
  setLocation,
  setMapSelectedJob,
} from "../../../store/slices/searchSlice"
import {
  AdvancedMarker,
  APIProvider,
  Map,
  useMap,
} from "@vis.gl/react-google-maps"
import Badge from "../../Badge"
import ResultList from "../ResultList"
import useSupercluster from "use-supercluster"
import axios from "axios"
import CheckBox from "../../FormInputs/CheckBox"
import Button from "../../FormInputs/Button"
import Image from "../../Image"

const {
  publicRuntimeConfig: { GOOGLE_MAPS_API_KEY },
} = getConfig()

const GoogleMap = ({
  children = [],
  className,
  isStatic = false,
  ...rootProps
}) => {
  const { t } = useTranslation("jobsearch")

  const dispatch = useDispatch()
  const search = useSelector((state) => state.search)

  const mapRef = useRef(null)

  const [zoom, setZoom] = useState(11)
  const [lastUpdate, setLastUpdate] = useState(null)
  const [activeGroup, setActiveGroup] = useState(null)
  const [center, setCenter] = useState({
    lat: 50.937570335219185,
    lng: 6.960454328369419,
  })
  const [refreshOnChange, setRefreshOnChange] = useState(true)
  const [clusterPoints, setClusterPoints] = useState([])

  const clusterOptions = {
    radius: 150,
    maxZoom: 14,
    minZoom: 7,
    minPoints: 1,

    // map: (props) => {
    //   console.log("map")
    //   return {
    //     count: props?.jobGroup?.jobs?.length,
    //     jobGroup: props?.jobGroup,
    //   }
    // },
    // reduce: (acc, props) => {
    //   console.log("reduce")
    //   acc.count += props?.jobGroup?.jobs?.length ?? 0
    //   return acc
    // },
  }

  const { clusters, supercluster } = useSupercluster({
    points: clusterPoints,
    bounds: [
      // bounds.nw.lng,
      search?.location?.bounds?.west,
      // bounds.se.lat,
      search?.location?.bounds?.south,
      // bounds.se.lng,
      search?.location?.bounds?.east,
      // bounds.nw.lat
      search?.location?.bounds?.north,
    ],
    zoom,
    options: clusterOptions,
  })

  const map = useMap()
  useEffect(() => {
    if (!map) return
    mapRef.current = map
  }, [map])

  // Jump to location on location change
  useEffect(() => {
    if (
      !search?.location?.lat ||
      !search?.location?.lng ||
      !search?.location?.range
    ) {
      return
    }

    const range =
      parseInt(search.location.range) === 0 ? 500 : search.location.range

    const zoomLevel = Math.round(14 - Math.log(range / 4) / Math.log(2))

    setCenter({
      lat: search.location.lat,
      lng: search.location.lng,
    })

    setZoom(zoomLevel)
  }, [search?.location?.lat, search?.location?.lng, search?.location?.range])

  // Manual Search
  const searchMap = (cancelToken = null) => {
    dispatch(searchJobs({ map: true, cancelToken }))
  }

  // Search on bounds change
  useEffect(() => {
    if (!search?.location?.bounds || isStatic || !refreshOnChange) return

    const cancelToken = axios.CancelToken.source()
    searchMap(cancelToken.token)

    return () => {
      cancelToken.cancel(`Cancel loading map results`)
    }
  }, [search?.location?.bounds, search?.term, search?.activeFilters])

  useEffect(() => {
    // group jobs by coordinates
    if (!search?.resultsMap || isStatic) return

    const jobGroups = Object.values(
      search?.resultsMap?.reduce((groups, job) => {
        const key = `${job.latitude}-${job.longitude}`
        if (!groups[key]) {
          groups[key] = {
            key: key,
            coordinates: {
              lat: parseFloat(job.latitude),
              lng: parseFloat(job.longitude),
            },
            jobs: [],
          }
        }
        groups[key].jobs = [...groups[key].jobs, job]

        return groups
      }, {}),
    )

    // set clusters
    setClusterPoints(
      jobGroups.map((jobGroup) => ({
        type: "Feature",
        properties: { cluster: false, jobGroupKey: jobGroup.key, jobGroup },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(jobGroup.coordinates.lng),
            parseFloat(jobGroup.coordinates.lat),
          ],
        },
      })),
    )
  }, [search.resultsMap, isStatic])

  useEffect(() => {
    if (!search.mapSelectedJob) return

    const jobGroup = clusterPoints.find((clusterPoint) =>
      clusterPoint.properties.jobGroup.jobs.find(
        (job) => job.id === search.mapSelectedJob,
      ),
    )

    if (!jobGroup) return

    setActiveGroup(jobGroup.key)
  }, [search.mapSelectedJob])

  const updateRef = useRef(null)

  return (
    <div className={cx(styles.GoogleMap, className)} id={"google-map-wrapper"}>
      <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
        <Map
          zoom={zoom}
          fullscreenControl={false}
          disableDefaultUI={isStatic}
          scrollwheel={!isStatic && activeGroup === null}
          draggable={!isStatic}
          zoomControl={!isStatic}
          disableDoubleClickZoom={!isStatic}
          minZoom={7}
          onZoomChanged={({ detail }) => {
            setZoom(detail.zoom)
          }}
          center={center}
          onBoundsChanged={({ detail: bounds }) => {
            if (isStatic) return

            if (updateRef.current) {
              clearTimeout(updateRef.current)
            }
            updateRef.current = setTimeout(() => {
              dispatch(
                setLocation({ ...search.location, bounds: bounds.bounds }),
              )
            }, 500)
          }}
          streetViewControl={false}
          mapId={"modal-map"}
          onClick={() => {
            setActiveGroup(null)
            dispatch(setMapSelectedJob(null))
          }}
        >
          {!isStatic
            ? clusters.map((cluster) => {
                const [longitude, latitude] = cluster.geometry.coordinates
                const {
                  cluster: isCluster,
                  point_count: pointCount,
                  jobGroup,
                  count,
                } = cluster.properties

                return isCluster ? (
                  <AdvancedMarker
                    position={{ lat: latitude, lng: longitude }}
                    key={`cluster-${cluster.id}`}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20,
                      )
                      setZoom(expansionZoom)
                      setCenter({ lat: latitude, lng: longitude })
                    }}
                    style={{ zIndex: -1 }}
                  >
                    <div
                      className={styles.clusterMarker}
                      style={{
                        width: `${
                          10 + (pointCount / clusterPoints.length) * 100
                        }px`,
                        height: `${
                          10 + (pointCount / clusterPoints.length) * 100
                        }px`,
                      }}
                    >
                      <span className={styles.clusterMarkerText}>
                        {pointCount}
                        {/*{pointCount} ({count})*/}
                      </span>
                    </div>
                  </AdvancedMarker>
                ) : (
                  <AdvancedMarker
                    key={jobGroup.key}
                    position={{ lat: latitude, lng: longitude }}
                    onClick={(e) => {
                      setActiveGroup(jobGroup.key)
                    }}
                    style={{ zIndex: -1 }}
                  >
                    <div className={"relative"}>
                      <Image
                        src={`/images/icons/pin_${
                          !activeGroup || activeGroup === jobGroup.key
                            ? "active"
                            : "default"
                        }.svg`}
                        alt={"map pin"}
                        width={40}
                        height={48}
                        className={"z-10 cursor-pointer drop-shadow-pin"}
                      />
                      <Badge
                        color={"red"}
                        circle
                        className={"absolute -right-4 -top-4"}
                      >
                        {jobGroup.jobs.length}
                      </Badge>

                      {activeGroup === jobGroup.key ? (
                        <div
                          className={
                            "absolute left-full top-4 max-h-[300px] w-[400px] overflow-y-auto"
                          }
                        >
                          <ResultList
                            results={jobGroup.jobs}
                            variant={"map"}
                            className={"gap-0"}
                            resultClassName={"border-b-0"}
                            resultVariant={"map"}
                            style={{ zIndex: 100 }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </AdvancedMarker>
                )
              })
            : null}
        </Map>

        {!isStatic ? (
          <div className={styles.refreshOptionWrapper}>
            <CheckBox
              onCheckedChange={(value) => {
                setRefreshOnChange(value)
              }}
              checked={refreshOnChange}
            >
              {t("map.refreshOnChange")}
            </CheckBox>
          </div>
        ) : null}

        {!isStatic && !refreshOnChange ? (
          <div className={styles.searchHereWrapper}>
            <Button
              variant={"tertiary"}
              onClick={() => {
                searchMap()
              }}
            >
              {t("map.searchHere")}
            </Button>
          </div>
        ) : null}
      </APIProvider>
    </div>
  )
}
const Marker = ({ children }) => children

export default GoogleMap
