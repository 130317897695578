export async function reverseGeo(lat, long) {
  if (!google?.maps?.Geocoder) return null

  const geocoder = new google.maps.Geocoder()

  const latlng = {
    lat: lat,
    lng: long,
  }

  try {
    const { results } = await geocoder.geocode({ location: latlng })
    if (results && results[0]) {
      return results[0]
    }
  } catch (e) {
    // handle exception
  }

  return null
}

export async function forwardGeo(placeId) {
  if (!google?.maps?.Geocoder) return null

  const geocoder = new google.maps.Geocoder()

  try {
    const { results } = await geocoder.geocode({ placeId })
    if (results && results[0]) {
      return results[0]
    }
  } catch (e) {
    // handle exception
  }

  return null
}

export const getAddressComponent = (place, types) => {
  return (
    place?.address_components?.find((component) =>
      types.map((type) => component.types.includes(type)).includes(true),
    )?.long_name ?? ""
  )
}
