import JobSearch from "./JobSearch"
import TextMedia from "./TextMedia"
import Hero from "./Hero"
import Text from "./Text"
import Cards from "./Cards"
import RecommendedJobsSlider from "./RecommendedJobsSlider"
import Benefits from "./Benefits"
import SingleQuote from "./SingleQuote"
import Facts from "./Facts"
import ContactPersons from "./ContactPersons"
import TextMediaSlider from "./TextMedia/TextMediaSlider"
import JobNewsletterSignup from "./JobSearch/JobNewsletterSignup"
import Accordion from "./Accordion"
import MultiColumnText from "./MultiColumnText"

const DynamicContent = ({ content }) => {
  if (!content) return null

  const { dynamic_content = [] } = content

  const contentTypes = {
    Set_DynamicContent_Hero: Hero,
    Set_DynamicContent_Text: Text,
    Set_DynamicContent_TextMedia: TextMedia,
    Set_DynamicContent_TextMediaSlider: TextMediaSlider,
    Set_DynamicContent_Cards: Cards,
    Set_DynamicContent_RecommendedJobsSlider: RecommendedJobsSlider,
    Set_DynamicContent_Benefits: Benefits,
    Set_DynamicContent_SingleQuote: SingleQuote,
    Set_DynamicContent_Facts: Facts,
    Set_DynamicContent_ContactPersons: ContactPersons,
    Set_DynamicContent_JobSearch: JobSearch,
    Set_DynamicContent_JobNewsletterSignup: JobNewsletterSignup,
    Set_DynamicContent_Accordion: Accordion,
    Set_DynamicContent_MultiColumnText: MultiColumnText,
  }

  return (
    <>
      {dynamic_content.map((data, i) => {
        if (!Object.keys(contentTypes).includes(data?.__typename)) {
          console.log(data?.__typename)
        }

        const Component = contentTypes[data?.__typename]
        if (!Component) return null

        return <Component key={i} data={data} tabIndex="0" />
      })}
    </>
  )
}

export default DynamicContent
