import cx from "classnames"
import Image from "../../Image"
import TextContent from "../../TextContent"
import styles from "./TextMediaPartial.module.scss"
import { useEffect, useState } from "react"
import { slugify } from "../../../lib/util"
import YoutubeEmbed from "../../YoutubeEmbed"

export const TextMediaPartial = ({
  data,
  textContentHeadlineClass = "",
  textContentToplineClass = "",
  textContentCopyClass = "",
  textContentSublineClass = "",
  slideIndex = null,
}) => {
  // console.log("TextMediaPartial", data)

  const [isImage, setIsImage] = useState(null)
  const [audioUrl, setAudioUrl] = useState(null)
  useEffect(() => {
    setIsImage(data?.media_type?.value === "image")

    if (data?.include_audio?.[0]?.audio?.is_audio) {
      setAudioUrl(data?.include_audio?.[0]?.audio?.permalink)
    }
  }, [data])

  const getEmbedUrlFromYoutubeUrl = (url) => {
    if (!url) return null
    const videoId = url?.split("v=")?.[1]?.split("&")?.[0]
    return `https://www.youtube.com/embed/${videoId}`
  }

  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <div
      className={cx(styles.section, {
        [styles.reverse]: data?.direction?.value === "right",
        [styles[`proportions-${data?.proportions?.value}`]]:
          data?.proportions?.value,
      })}
    >
      <div className={cx(styles.wrapper)}>
        <div className={styles.textContent}>
          <TextContent
            content={data?.textcontent}
            headlineClassName={textContentHeadlineClass}
            toplineClassName={textContentToplineClass}
            copyClassName={textContentCopyClass}
            sublineClassName={textContentSublineClass}
          />
          {audioUrl ? (
            <audio controls className={styles.audio}>
              <source src={audioUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          ) : null}
        </div>
        <div className={styles.media}>
          {isImage ? (
            <div className={styles.imageWrapper}>
              <Image
                loading={slideIndex === 0 ? "eager" : "lazy"}
                alt={data?.image?.alt}
                src={data?.image?.permalink}
                fill
                style={{
                  objectPosition: data?.image?.focus_css,
                  objectFit: data?.image_fit?.value,
                }}
                sizes="(max-width: 767px) 100vw, 50vw"
              />
            </div>
          ) : null}

          {isImage && data?.image?.alt ? (
            <div className={styles.imageCaption}>{data?.image?.alt}</div>
          ) : null}

          {isClient && !isImage ? (
            <YoutubeEmbed
              videos={[
                {
                  url: getEmbedUrlFromYoutubeUrl(data?.video),
                  title: `youtube-video-${slugify(
                    data?.textcontent?.[0]?.headline ||
                      data?.textcontent?.[0]?.subline ||
                      data?.textcontent?.[0]?.topline,
                  )}`,
                },
              ]}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default TextMediaPartial
