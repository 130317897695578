import styles from "./Hero.module.scss"
import Section from "../Section"
import Image from "../Image"
import SearchBox from "../JobSearch/SearchBox"
import { useEffect, useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, EffectFade } from "swiper/modules"
import "swiper/css"
import "swiper/css/effect-fade"
import cx from "classnames"
import { stripHtml } from "../../lib/util"

export const Hero = ({ data }) => {
  // console.log("Hero", data)

  const isHomeHero = data?.is_home_hero
  const hasMultipleImages = data?.background_images?.length > 1

  const fixSearchBoxHeight = () => {
    if (!searchBoxRef.current) return

    if (window.getComputedStyle(searchBoxRef.current).maxWidth === "100%") {
      searchBoxRef.current.style.marginBottom = "unset"
    } else {
      searchBoxRef.current.style.marginBottom = `-${
        searchBoxRef.current.offsetHeight / 3
      }px`
    }
  }

  const searchBoxRef = useRef(null)
  useEffect(() => {
    if (!isHomeHero) return

    fixSearchBoxHeight()

    window.addEventListener("resize", fixSearchBoxHeight)

    return () => {
      window.removeEventListener("resize", fixSearchBoxHeight)
    }
  }, [searchBoxRef.current])

  const swiperRef = useRef(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  return (
    <Section
      className={cx(styles.Hero, {
        [styles.homeHero]: isHomeHero,
        [styles.pageHero]: !isHomeHero,
      })}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      container={false}
    >
      <div className={styles.heroInner}>
        <div className={styles.backgroundImageContainer}>
          {hasMultipleImages ? (
            <Swiper
              ref={swiperRef}
              pagination={false}
              navigation={false}
              slidesPerView={1}
              className={styles.swiper}
              modules={[Autoplay, EffectFade]}
              speed={1600}
              effect={"fade"}
              autoplay={{
                delay: 5000,
              }}
              loop={true}
              onActiveIndexChange={(swiper) => {
                setActiveSlideIndex(swiper.realIndex)
              }}
            >
              {data?.background_images?.map((image, i) => (
                <SwiperSlide key={i}>
                  <div
                    className={cx("relative h-full w-full", {
                      hidden:
                        activeSlideIndex !== i && activeSlideIndex !== i + 1,
                    })}
                  >
                    <Image
                      priority={i === 0}
                      loading={i === 0 ? "eager" : "lazy"}
                      alt={image?.alt}
                      src={image?.permalink}
                      fill
                      style={{
                        objectPosition: image?.focus_css,
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Image
              alt={data?.background_images?.[0]?.alt}
              src={data?.background_images?.[0]?.permalink}
              fill
              style={{
                objectPosition: data?.background_images?.[0]?.focus_css,
                objectFit: "cover",
              }}
            />
          )}
        </div>

        {isHomeHero ? (
          <SearchBox
            ref={searchBoxRef}
            className={styles.searchBox}
            showFilter={false}
            showJobCount={true}
            topline={data?.topline}
            headline={data?.headline}
          />
        ) : null}

        {!isHomeHero ? (
          <div className={styles.textContainer}>
            <div
              className={styles.topline}
              dangerouslySetInnerHTML={{ __html: data?.topline }}
            ></div>
            <div className={styles.headline}>
              <h1
                dangerouslySetInnerHTML={{ __html: stripHtml(data?.headline) }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Section>
  )
}

export default Hero
