import { useState, useEffect } from "react"
import tailwindConfig from "tailwind.config.js"

const { screens } = tailwindConfig.theme

export function useCurrentMediaWidth() {
  const [currentScreen, setCurrentScreen] = useState("default width")

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth

      const newScreen = Object.keys(screens)
        .reverse()
        .find((key) => {
          if (screenWidth >= parseInt(screens[key])) {
            return true
          }
        })

      setCurrentScreen(newScreen || "default width")
    }

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return currentScreen
}

export function useSmallerThan(breakpoint) {
  const currentScreen = useCurrentMediaWidth()

  const screenKeys = Object.keys(screens)
  const currentScreenIndex = screenKeys.indexOf(currentScreen)
  const breakpointIndex = screenKeys.indexOf(breakpoint)

  return currentScreenIndex < breakpointIndex
}
