import * as Dialog from "@radix-ui/react-dialog"
import styles from "./MapModal.module.scss"
import { Sliders, X } from "react-feather"
import useTranslation from "next-translate/useTranslation"
import GoogleMap from "../GoogleMap"
import SearchBox from "../SearchBox"
import Badge from "../../Badge"
import Button from "../../FormInputs/Button"
import { getActiveFilterCount } from "../../../store/slices/searchSlice"
import { useSelector } from "react-redux"
import ResultList from "../ResultList"

const MapModal = ({
  open,
  onOpenChange = () => {},
  setFilterModalOpen = () => {},
}) => {
  const { t } = useTranslation("jobsearch")

  const activeFilterCount = useSelector(getActiveFilterCount)
  const search = useSelector((state) => state.search)

  return (
    <div className={styles.MapModal}>
      <Dialog.Root open={open} onOpenChange={onOpenChange}>
        <Dialog.Overlay className={styles.overlay} />
        <Dialog.Content className={styles.content}>
          <Dialog.Close className={styles.close}>
            <Button
              as={"div"}
              variant={"tertiary"}
              className={"aspect-square p-2"}
              iconRight={<X />}
            />
          </Dialog.Close>

          <aside>
            <SearchBox mapMode>
              <Button
                iconLeft={<Sliders />}
                variant={"secondary"}
                onClick={() => {
                  setFilterModalOpen(true)
                }}
              >
                Filter
                {activeFilterCount > 0 ? (
                  <Badge circle color={"red"}>
                    {activeFilterCount}
                  </Badge>
                ) : null}
              </Button>
            </SearchBox>
            <ResultList
              results={search?.resultsMap}
              display={"list"}
              className={"h-full gap-0 overflow-y-auto"}
              resultClassName={"border-b-0"}
              resultVariant={"map_sidebar"}
            />
          </aside>
          <main>
            <GoogleMap />
          </main>
        </Dialog.Content>
      </Dialog.Root>
    </div>
  )
}

export default MapModal
