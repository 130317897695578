/** @type {import('tailwindcss').Config} */

module.exports = {
  content: ["./pages/**/*.{js,scss}", "./components/**/*.{js,scss}"],
  important: true,
  theme: {
    fontFamily: {
      headline: ["Helvetica Neue LT Std", "Arial", "sans-serif"],
      sans: ["Helvetica Neue", "Arial", "sans-serif"],
      serif: ["Helvetica Neue", "Arial", "serif"],
    },
    screens: {
      sm: "568px",
      md: "768px",
      lg: "1024px",
      xl: "1440px",
      // "2xl": "1920px",
      // "3xl": "2560px",
    },
    extend: {
      spacing: {
        18: "4.5rem",
        26: "6.5rem",
        49: "12.25rem",
      },

      fontSize: {
        xs: ["0.75rem", { lineHeight: "150%" }],
        sm: ["0.875rem", { lineHeight: "150%" }],
        base: ["1.125rem", { lineHeight: "150%" }],
        lg: ["1.125rem", { lineHeight: "150%" }],
        xl: ["1.25rem", { lineHeight: "150%" }],
        "2xl": ["1.5rem", { lineHeight: "150%" }],
        "3xl": ["1.875rem", { lineHeight: "150%" }],
        "4xl": ["2.25rem", { lineHeight: "150%" }],
        "5xl": ["3rem", { lineHeight: "150%" }],
        "6xl": ["4rem", { lineHeight: "150%" }],
      },
      colors: {
        black: "#000000",
        white: "#FFFFFF",

        "toom-red": {
          900: "#6D1516",
          800: "#891112",
          700: "#9B0E10",
          600: "#B60307",
          500: "#C90C0F",
          DEFAULT: "#C90C0F",
          400: "#E11619",
          300: "#F35355",
          200: "#FC8E8E",
          100: "#FFC6C6",
          50: "#FFEDED",
        },

        "toom-green": {
          900: "#074621",
          800: "#055324",
          700: "#026129",
          600: "#016F2E",
          500: "#007C34",
          DEFAULT: "#007C34",
          400: "#1B934C",
          300: "#3FA96A",
          200: "#6BC08D",
          100: "#A0D6B5",
          50: "#E4F6EC",
        },

        neutral: {
          900: "#171717",
          800: "#2C2C2C",
          700: "#3C3C3C",
          600: "#666666",
          500: "#525252",
          DEFAULT: "#525252",
          400: "#B6B6B6",
          300: "#D4D4D4",
          200: "#E5E5E5",
          100: "#F5F5F5",
          50: "#F9FAFB",
        },

        error: {
          900: "#6D1516",
          800: "#891112",
          700: "#9B0E10",
          600: "#B60307",
          500: "#C90C0F",
          DEFAULT: "#C90C0F",
          400: "#E11619",
          300: "#F35355",
          200: "#FC8E8E",
          100: "#FFC6C6",
          50: "#FFEDED",
        },

        warning: {
          900: "#713F12",
          800: "#854D0E",
          700: "#A16207",
          600: "#CA8A04",
          500: "#EAB308",
          DEFAULT: "#EAB308",
          400: "#FACC15",
          300: "#FDE047",
          200: "#FEF08A",
          100: "#FEF9C3",
          50: "#FEFCE8",
        },

        success: {
          900: "#14532D",
          800: "#166534",
          700: "#15803D",
          600: "#16A34A",
          500: "#22C55E",
          DEFAULT: "#22C55E",
          400: "#4ADE80",
          300: "#86EFAC",
          200: "#BBF7D0",
          100: "#DCFCE7",
          50: "#F0FDF4",
        },

        info: {
          900: "#031633",
          800: "#052C65",
          700: "#084298",
          600: "#0A58CA",
          500: "#0D6EFD",
          DEFAULT: "#0D6EFD",
          400: "#3D8BFD",
          300: "#6EA8FE",
          200: "#9EC5FE",
          100: "#CFE2FF",
          50: "#ECF3FF",
        },
      },

      boxShadow: {
        card: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        sidemenu: "0px 4px 15px rgba(0, 0, 0, 0.25)",
        submenu: "0px 6px 8px 0px rgba(0, 0, 0, 0.10)",
        xs: "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)",
        sm: "0px 4px 6px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)",
        md: "0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.10)",
        lg: "0px 10px 10px 0px rgba(0, 0, 0, 0.04), 0px 20px 25px 0px rgba(0, 0, 0, 0.10)",
        xl: "0px 25px 50px 0px rgba(0, 0, 0, 0.25)",
      },
      dropShadow: {
        pin: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
      borderRadius: {
        sm: "4px",
        card: "16px",
      },
    },
  },
  corePlugins: {
    container: false,
  },
  plugins: [
    function ({ addComponents }) {
      addComponents({
        ".container": {
          maxWidth: "100%",
          margin: "0 auto",
          padding: "0 1rem",
          "@screen sm": {
            maxWidth: "520px",
          },
          "@screen md": {
            maxWidth: "680px",
          },
          "@screen lg": {
            maxWidth: "1100px",
          },
          "@screen xl": {
            maxWidth: "1200px",
          },
          // "@screen 2xl": {
          //   maxWidth: "1520px",
          // },
          // "@screen 3xl": {
          //   maxWidth: "1600px",
          // },
        },
      })
    },
    require("@tailwindcss/container-queries"),
  ],
}
