"use client"

import styles from "./SearchBox.module.scss"
import useTranslation from "next-translate/useTranslation"
import cx from "classnames"
import * as Form from "@radix-ui/react-form"
import Input from "../../FormInputs/Input"
import Button from "../../FormInputs/Button"
import LocationAutocompleteInput from "../../FormInputs/LocationAutocompleteInput"
import { getJobCount, setTerm } from "../../../store/slices/searchSlice"
import { useAppDispatch, useAppSelector } from "../../../store"
import { useSelector } from "react-redux"
import { forwardRef, useEffect } from "react"
import { useRouter } from "next/router"

const SearchBox = forwardRef(
  (
    {
      children = [],
      className,
      fullWidth = false,
      showJobCount = false,
      showFilter = false,
      mapMode = false,
      termRequired = false,
      topline = null,
      headline = null,
    },
    ref,
  ) => {
    const dispatch = useAppDispatch()
    const search = useAppSelector((state) => state.search)

    const router = useRouter()

    const jobCount = useSelector(getJobCount)

    const { t } = useTranslation("jobsearch")

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search)
      const val = urlParams.get("search")
      if (val) dispatch(setTerm(escapeHTML(val)))
    }, [])

    useEffect(() => {
      // replace "search" parameter in url
      const urlParams = new URLSearchParams(window.location.search)
      if (search.term) {
        urlParams.set("search", search.term)
      } else {
        urlParams.delete("search")
      }
      const params = urlParams.toString()
      const newRelativePathQuery =
        window.location.pathname + (params ? "?" + params : "")

      history.replaceState(window.history.state, "", newRelativePathQuery)
    }, [search?.term])

    const escapeHTML = (str) => {
      return str.replace(/[&<>"'\/]/g, function (char) {
        switch (char) {
          case "&":
            return "&amp;"
          case "<":
            return "&lt;"
          case ">":
            return "&gt;"
          case '"':
            return "&quot;"
          case "\\":
            return "&#39;"
          case "/":
            return "&#x2F;"
          default:
            return char
        }
      })
    }

    return (
      <div
        ref={ref}
        className={cx(styles.SearchBox, className, {
          [styles.fullWidth]: fullWidth,
          [styles.mapMode]: mapMode,
        })}
      >
        {showJobCount && jobCount > 0 ? (
          <div className={styles.jobCount}>
            <span>{t("searchbox.jobcount", { count: jobCount })}</span>
          </div>
        ) : null}

        {!mapMode ? (
          <div>
            {topline ? (
              <div dangerouslySetInnerHTML={{ __html: topline }}></div>
            ) : null}
            <h1
              dangerouslySetInnerHTML={{
                __html: headline ?? t("searchbox.headline"),
              }}
            />
          </div>
        ) : null}

        <Form.Root
          className={styles.inputs}
          onSubmit={(e) => {
            e.preventDefault()
            router.push({
              pathname: "/jobs",
              query: {
                search: search.term,
              },
            })
          }}
        >
          <div className={styles.row}>
            <div className={cx(styles.inputWrapper, styles.termWrapper)}>
              <Input
                name="term"
                label={t("searchbox.input.term.label")}
                value={search.term}
                setValue={(e) => {
                  dispatch(setTerm(escapeHTML(e)))
                }}
                clearable={true}
              />
            </div>
            <div className={cx(styles.inputWrapper, styles.locationWrapper)}>
              <LocationAutocompleteInput
                name="location"
                label={t("searchbox.input.location.label")}
              />
            </div>

            <div className={styles.actionWrapper}>
              {!mapMode ? (
                <Form.Submit asChild>
                  <Button variant={"secondary"}>
                    {t("searchbox.input.action.label")}
                  </Button>
                </Form.Submit>
              ) : null}

              {children}
            </div>
          </div>
        </Form.Root>
      </div>
    )
  },
)

SearchBox.displayName = "SearchBox"

export default SearchBox
