import styles from "./JobNewsletterSignup.module.scss"
import cx from "classnames"
import useTranslation from "next-translate/useTranslation"
import Section from "../../Section"
import Image from "../../Image"
import Input from "../../FormInputs/Input"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import * as Form from "@radix-ui/react-form"
import Button from "../../FormInputs/Button"
import { setTerm } from "../../../store/slices/searchSlice"
import LocationAutocompleteInput from "../../FormInputs/LocationAutocompleteInput"
import axios from "axios"

import getConfig from "next/config"

const {
  publicRuntimeConfig: { API_URL },
} = getConfig()

const JobNewsletterSignup = ({ data = {}, className = "" }) => {
  // console.log("JobNewsletterSignup", data)

  const { t } = useTranslation("jobsearch")

  const search = useSelector((state) => state.search)
  const dispatch = useDispatch()

  const [userEmail, setUserEmail] = useState("")

  const [submitted, setSubmitted] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)
  const handleSubmit = (e) => {
    e.preventDefault()

    const data = {
      term: search.term,
      email: userEmail,
    }

    if (search?.location?.display) {
      data.location = {
        description: search?.location?.display,
        lat: search?.location?.lat,
        lng: search?.location?.lng,
        range: search?.location?.range,
      }
    }

    axios
      .post(`${API_URL}/api/jobs/newsletter`, data)
      .then((res) => {
        setSubmitted(true)
        setSubmissionError(res.status !== 201)
      })
      .catch((err) => {
        setSubmitted(true)
        setSubmissionError(true)
      })
  }

  return (
    <Section
      className={cx(styles.JobNewsletterSignup, className)}
      tabIndex={0}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <div
        className={cx(styles.box, {
          [styles.submitted]: submitted,
        })}
      >
        {submitted ? (
          <div className={styles.submission}>
            <div className={styles.title}>
              {t(
                `newsletterbox.submission.${
                  submissionError ? "error" : "success"
                }.title`,
              )}
            </div>
            <div className={styles.text}>
              {t(
                `newsletterbox.submission.${
                  submissionError ? "error" : "success"
                }.text`,
              )}
            </div>

            <Button
              variant={"secondary"}
              onClick={() => {
                setSubmitted(false)
                setSubmissionError(false)
              }}
              className={styles.button}
            >
              {t(
                `newsletterbox.submission.${
                  submissionError ? "error" : "success"
                }.repeat`,
              )}
            </Button>
          </div>
        ) : null}

        {!submitted ? (
          <>
            <div className={styles.image}>
              <Image
                src={data?.image?.permalink}
                alt={data?.image?.alt}
                width={data?.image?.width}
                height={data?.image?.height}
                style={{
                  aspectRatio: `${data?.image?.width} / ${data?.image?.height}`,
                }}
              />
            </div>

            <div className={styles.form}>
              <h2 className={styles.title}>{t("newsletterbox.title")}</h2>
              <Form.Root className={styles.inputs} onSubmit={handleSubmit}>
                <div className={styles.row}>
                  <div className={cx(styles.inputWrapper, styles.termWrapper)}>
                    <Input
                      name="term"
                      label={t("searchbox.input.term.label")}
                      value={search.term}
                      setValue={(val) => {
                        dispatch(setTerm(val))
                      }}
                      required={true}
                    />
                  </div>
                  <div
                    className={cx(styles.inputWrapper, styles.locationWrapper)}
                  >
                    <LocationAutocompleteInput
                      name="location"
                      label={t("searchbox.input.location.label")}
                    />
                  </div>

                  <Input
                    label={t("newsletterbox.email")}
                    value={userEmail}
                    setValue={(val) => setUserEmail(val)}
                    type={"email"}
                    required={true}
                    className={styles.email}
                  />

                  <Form.Submit className={styles.submit} style={{ zIndex: -1 }}>
                    <Button variant={"secondary"} as={"div"}>
                      {t("newsletterbox.submit")}
                    </Button>
                  </Form.Submit>
                </div>
              </Form.Root>

              <div
                className={styles.privacyNotice}
                dangerouslySetInnerHTML={{
                  __html: t("newsletterbox.privacyNotice", {
                    url: data?.privacy_policy_link,
                  }),
                }}
              ></div>
            </div>
          </>
        ) : null}
      </div>
    </Section>
  )
}

export default JobNewsletterSignup
