import Section from "../Section"
import styles from "./SingleQuote.module.scss"
import Image from "../Image"

export const SingleQuote = ({ data }) => {
  // console.log("SingleQuote", data)

  return (
    <Section
      className={styles.SingleQuote}
      extraSpacing={true}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <div className={styles.inner}>
        <div className={styles.image}>
          <Image
            alt={data?.image?.alt}
            src={data?.image?.permalink}
            fill
            style={{
              objectPosition: data?.image?.focus_css,
              objectFit: "cover",
            }}
          />
          <Image
            alt={"toom icon"}
            src={"/images/logos/favicon.svg"}
            width={55}
            height={44}
            className={"absolute -bottom-3 -right-5"}
          />
        </div>
        <div className={styles.content}>
          {data?.quote ? (
            <div
              className={styles.quote}
              dangerouslySetInnerHTML={{ __html: data?.quote }}
            />
          ) : null}
          {data?.name ? (
            <div
              className={styles.name}
              dangerouslySetInnerHTML={{ __html: data?.name }}
            />
          ) : null}
          {data?.position ? (
            <div
              className={styles.position}
              dangerouslySetInnerHTML={{ __html: data?.position }}
            />
          ) : null}
        </div>
      </div>
    </Section>
  )
}

export default SingleQuote
