import * as Dialog from "@radix-ui/react-dialog"
import styles from "./FilterModal.module.scss"
import { X } from "react-feather"
import useTranslation from "next-translate/useTranslation"
import Button from "../../FormInputs/Button"
import { useEffect, useState } from "react"
import FilterList from "../FilterList"

const FilterModal = ({ open = false, onOpenChange = () => {} }) => {
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  const { t } = useTranslation("jobsearch")

  return isClient ? (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Overlay className={styles.overlay} />
      <Dialog.Content className={styles.content}>
        <Dialog.Close className={styles.closeWrapper} asChild={"div"}>
          <div className={"flex items-center gap-2"}>
            <Button variant={"tertiary"} asStyle={"link"} iconRight={<X />}>
              <span className={"block lg:hidden"}>
                {t("filterModal.close")}
              </span>
            </Button>
          </div>
        </Dialog.Close>

        <div className={"p-6 py-16 lg:px-20 lg:py-4"}>
          <FilterList
            className={styles.FilterList}
            accordionClassName={styles.accordion}
            sectionClassName={styles.section}
            showResultButton={true}
            resultButtonAction={() => {
              onOpenChange(false)
            }}
            desktopGroupCollapse={false}
          />
        </div>
      </Dialog.Content>
    </Dialog.Root>
  ) : null
}

export default FilterModal
