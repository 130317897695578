import styles from "./Cards.module.scss"
import Section from "../Section"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Navigation, Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/effect-fade"
import cx from "classnames"
import TextContent from "../TextContent"
import Card from "../Card"
import useTranslation from "next-translate/useTranslation"
import Button from "../FormInputs/Button"
import { ArrowRight, ChevronLeft, ChevronRight } from "react-feather"
import { useEffect, useState } from "react"

export const Cards = ({ data }) => {
  // console.log("Cards", data)

  const isGrid = data?.display_as?.value === "grid" || false

  const [sliderID, setSliderID] = useState(null)
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
    setSliderID("card-slider-" + Math.random().toString(36).substr(2, 9))
  }, [])

  const { t } = useTranslation("common")

  const [swiperNextHidden, setSwiperNextHidden] = useState(false)
  const [swiperPrevHidden, setSwiperPrevHidden] = useState(false)

  const toggleNavigationButtons = (swiper) => {
    if (data?.cards?.length <= swiper.params.slidesPerView) {
      setSwiperNextHidden(true)
      setSwiperPrevHidden(true)
    } else {
      setSwiperNextHidden(false)
      setSwiperPrevHidden(false)

      if (swiper.isBeginning) {
        setSwiperPrevHidden(true)
      } else if (swiper.isEnd) {
        setSwiperNextHidden(true)
      }
    }
  }

  const CardEL = (card, i) => (
    <Card
      key={`card-${i}`}
      link={card?.link}
      image={card?.image}
      heightImage={256}
      className={styles.card}
    >
      <div
        className={styles.headline}
        dangerouslySetInnerHTML={{ __html: card?.headline }}
      ></div>
      <div
        className={styles.copy}
        dangerouslySetInnerHTML={{ __html: card?.copy }}
      ></div>
      {card?.link ? (
        <div className={styles.link}>
          <Button
            variant={"secondary"}
            asStyle={"link"}
            iconRight={<ArrowRight />}
            className={"p-0"}
          >
            {t("card.more")}
          </Button>
        </div>
      ) : null}
    </Card>
  )

  return !isClient || !sliderID ? null : (
    <Section
      className={cx(styles.Cards)}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <TextContent content={data?.textcontent} className={styles.textContent} />

      {!isGrid ? (
        <div className={styles.swiperContainer}>
          <Swiper
            onInit={(swiper) => {
              toggleNavigationButtons(swiper)
            }}
            onBreakpoint={(swiper) => {
              toggleNavigationButtons(swiper)
            }}
            onActiveIndexChange={(swiper) => {
              toggleNavigationButtons(swiper)
            }}
            pagination={{
              el: `.card-swiper-pagination-${sliderID}`,
              enabled: true,
              clickable: true,
              bulletActiveClass: styles.bulletActive,
              renderBullet: (index, className) => {
                return `<div
                  data-index="${index}"
                  class="${cx(className, styles.bullet)}"
                ></div>`
              },
            }}
            navigation={{
              nextEl: ".swiper-button-next-" + sliderID,
              prevEl: ".swiper-button-prev-" + sliderID,
            }}
            slidesPerView={3}
            className={styles.swiper}
            autoHeight={true}
            modules={[Autoplay, Pagination, Navigation]}
            autoplay={false}
            // autoplay={{
            //   delay: 3200,
            //   disableOnInteraction: true,
            //   pauseOnMouseEnter: true,
            //   waitForTransition: false,
            // }}
            loop={false}
            spaceBetween={24}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {data?.cards?.map((card, i) => (
              <SwiperSlide key={i}>{CardEL(card, i)}</SwiperSlide>
            ))}
          </Swiper>

          <>
            <div
              className={cx(
                `card-swiper-pagination-${sliderID}`,
                styles.swiperPagination,
              )}
            />

            <div
              className={cx(
                styles.swiperNavNext,
                "swiper-button-next-" + sliderID,
                {
                  hidden: swiperNextHidden,
                },
              )}
            >
              <ChevronRight size={24} />
            </div>
            <div
              className={cx(
                styles.swiperNavPrev,
                "swiper-button-prev-" + sliderID,
                {
                  hidden: swiperPrevHidden,
                },
              )}
            >
              <ChevronLeft size={24} />
            </div>
          </>
        </div>
      ) : (
        <div className={styles.cardGrid}>
          {data?.cards?.map((card, i) => CardEL(card, i))}
        </div>
      )}
    </Section>
  )
}

export default Cards
