import React from "react"
import * as RadioGroup from "@radix-ui/react-radio-group"
import { uuid } from "../../../../lib/util"
import styles from "./RadioButton.module.scss"
import { Circle } from "react-feather"
import cx from "classnames"

function RadioButton({
  children = [],
  className,
  radioClassName = "",
  value = null,
  rootProps = {},
  cKey = "",
}) {
  const id = cKey || `rb-${uuid()}`

  return (
    <div className={cx("flex items-center gap-2", className)}>
      <RadioGroup.Item
        value={value}
        id={id}
        className={cx(styles.RadioButton, radioClassName)}
      >
        <RadioGroup.Indicator className={styles.indicator}>
          <Circle />
        </RadioGroup.Indicator>
      </RadioGroup.Item>
      <label htmlFor={id} className={"mt-0.5 text-sm"}>
        {children}
      </label>
    </div>
  )
}

export default RadioButton
