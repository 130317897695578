import cx from "classnames"
import Section from "../Section"
import TextContent from "../TextContent"
import styles from "./Text.module.scss"
import { useEffect, useState } from "react"

export const Text = ({ data }) => {
  // console.log("Text", data)

  const [alignClass, setAlignClass] = useState(null)
  const [sizeClass, setSizeClass] = useState(null)
  useEffect(() => {
    setAlignClass(`align-${data?.alignment?.value ?? "left"}`)
    setSizeClass(`size-${data?.size?.value ?? "75"}`)
  }, [data])

  return (
    <Section
      className={cx(styles.section, {
        [styles[alignClass]]: data?.alignment?.value,
        [styles[sizeClass]]: data?.size?.value,
      })}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <TextContent
        content={data?.textcontent}
        className={styles.textContent}
        buttonAlignment={data?.alignment?.value || "left"}
      />
    </Section>
  )
}

export default Text
