"use client"

import React, { useEffect, useRef, useState } from "react"
import styles from "./LocationAutocompleteInput.module.scss"
import cx from "classnames"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { Combobox } from "@headlessui/react"
import getConfig from "next/config"
import { ChevronDown, Loader, Navigation, X } from "react-feather"
import { getAddressComponent, reverseGeo } from "../../../lib/reverse-geocoding"
import useTranslation from "next-translate/useTranslation"
import LocationPermissionModal from "../../JobSearch/LocationPermissionModal"
import { useDispatch, useSelector } from "react-redux"
import { setLocation } from "../../../store/slices/searchSlice"
import * as Select from "@radix-ui/react-select"

const {
  publicRuntimeConfig: { GOOGLE_MAPS_API_KEY },
} = getConfig()

function LocationAutocompleteInput({ className }) {
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  const [isInitialPlaceLoad, setIsInitialPlaceLoad] = useState(true)

  const dispatch = useDispatch()
  const search = useSelector((state) => state.search)

  const { t } = useTranslation("jobsearch")

  const inputRef = useRef(null)

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: {
      types: ["(regions)"],
      componentRestrictions: { country: ["DE"] },
      debounce: 300,
      language: "de",
    },
  })

  const [selectedPlace, setSelectedPlace] = useState(null)
  const [displayValue, setDisplayValue] = useState(
    search?.location?.display || "",
  )
  useEffect(() => {
    if (!selectedPlace || !selectedPlace?.place_id) return

    if (selectedPlace.place_id === "custom") {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          reverseGeo(latitude, longitude).then((place) => {
            const display = `${getAddressComponent(place, [
              "postal_code",
            ])} ${getAddressComponent(place, [
              "locality",
              "administrative_area_level_3",
            ])}`

            setDisplayValue(display)

            dispatch(
              setLocation({
                ...search.location,
                display,
                lat: latitude,
                lng: longitude,
                city: getAddressComponent(place, [
                  "locality",
                  "administrative_area_level_3",
                ]),
              }),
            )
          })
        },
        (error) => {
          setLocationPermissionModalOpen(true)
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      )
    } else {
      placesService.getDetails(
        { placeId: selectedPlace.place_id },
        (placeDetails) => {
          const display = `${getAddressComponent(placeDetails, [
            "postal_code",
          ])} ${getAddressComponent(placeDetails, [
            "locality",
            "administrative_area_level_3",
          ])}`

          setDisplayValue(display)

          dispatch(
            setLocation({
              ...search.location,
              display,
              lat: placeDetails.geometry.location.lat(),
              lng: placeDetails.geometry.location.lng(),
              city: placeDetails.address_components.find(
                (component) =>
                  component.types.includes("locality") ||
                  component.types.includes("administrative_area_level_3"),
              )?.long_name,
            }),
          )
        },
      )
    }
  }, [selectedPlace])

  const [locationPermissionModalOpen, setLocationPermissionModalOpen] =
    useState(false)

  const comboBoxButtonRef = useRef(null)
  const setLocationFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const city = urlParams.get("city")
    if (!city) return

    getPlacePredictions({ input: city })
  }

  useEffect(() => {
    setLocationFromUrl()
  }, [])

  useEffect(() => {
    if (!isInitialPlaceLoad) return

    const selected = placePredictions?.[0]
    if (selected) {
      setIsInitialPlaceLoad(false)
      setSelectedPlace(selected)
    }
  }, [placePredictions])

  useEffect(() => {
    // replace "city" parameter in url
    const urlParams = new URLSearchParams(window.location.search)
    if (search?.location?.city) {
      urlParams.set("city", search.location.city)
    } else {
      urlParams.delete("city")
    }
    const params = urlParams.toString()
    const newRelativePathQuery =
      window.location.pathname + (params ? "?" + params : "")

    history.replaceState(window.history.state, "", newRelativePathQuery)
  }, [search?.location?.city])

  useEffect(() => {
    if (!search?.location?.display) {
      if (displayValue) {
        setDisplayValue("")
      }
    }

    if (!displayValue) {
      setDisplayValue(search?.location?.display)
    }
  }, [search.location.display])

  return isClient ? (
    <div
      className={cx(
        styles.LocationAutocompleteInput,
        {
          [styles.showRangeSelector]: search?.location?.display,
        },
        className,
      )}
    >
      <label htmlFor="combobox-input" className={styles.Label}>
        {t("searchbox.input.location.label")}
      </label>

      <div
        className={"flex w-full flex-wrap items-center gap-y-2 sm:flex-nowrap"}
      >
        <Combobox value={displayValue} onChange={setSelectedPlace}>
          <div className={styles.InputWrapper}>
            <Combobox.Input
              ref={inputRef}
              id={"combobox-input"}
              className={styles.Input}
              onChange={(evt) => {
                if (evt.target.value.length) {
                  getPlacePredictions({ input: evt.target.value })
                }
              }}
              onFocus={() => {
                comboBoxButtonRef?.current?.click()
              }}
              onKeyDown={(e) => {
                if (
                  isPlacePredictionsLoading &&
                  (e.key === "ArrowDown" || e.key === "Enter")
                ) {
                  e.preventDefault()
                }
              }}
            />
            {isPlacePredictionsLoading ? (
              <div className={styles.loadingIndicator}>
                <Loader className={"duration-1000 motion-safe:animate-spin"} />
              </div>
            ) : search?.location?.display ? (
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()

                  setDisplayValue("")
                  dispatch(
                    setLocation({
                      ...search.location,
                      city: null,
                      lat: null,
                      lng: null,
                      display: null,
                    }),
                  )
                }}
                className={styles.clearButton}
                role={"button"}
                aria-label={t("common:form.aria.clear")}
              >
                <X />
              </div>
            ) : null}
          </div>
          <Combobox.Button
            ref={comboBoxButtonRef}
            aria-label={t("jobsearch.searchbox.aria.location.label")}
          />
          <Combobox.Options className={styles.Options}>
            {placePredictions.map((place, i) => (
              <Combobox.Option
                key={i}
                value={{
                  description: place.description,
                  place_id: place.place_id,
                }}
              >
                {({ active }) => (
                  <div
                    className={cx(styles.Item, {
                      [styles.active]: active,
                    })}
                  >
                    {place.description}
                  </div>
                )}
              </Combobox.Option>
            ))}
            <Combobox.Option
              key={0}
              value={{
                description: "custom",
                place_id: "custom",
              }}
            >
              {({ active }) => (
                <>
                  {placePredictions?.length > 0 ? (
                    <div className={styles.separator}></div>
                  ) : null}
                  <div
                    className={cx(styles.Item, {
                      [styles.active]: active,
                    })}
                  >
                    <Navigation /> {t("locationAutocomplete.detection.label")}
                  </div>
                </>
              )}
            </Combobox.Option>
          </Combobox.Options>
        </Combobox>

        {search?.location?.display ? (
          <Select.Root
            onValueChange={(value) => {
              dispatch(
                setLocation({
                  ...search.location,
                  range: parseInt(value),
                }),
              )
            }}
            defaultValue={search.defaultRange}
            className={styles.range}
          >
            <Select.Trigger
              className={styles.rangeTrigger}
              role={"combobox"}
              aria-label={t("searchbox.aria.range.label")}
            >
              <Select.Value aria-label={search.location.range}>
                {search.ranges[search.location.range]}
              </Select.Value>
              <Select.Icon>
                <ChevronDown />
              </Select.Icon>
            </Select.Trigger>
            <Select.Content position="popper" side={"bottom"}>
              <Select.Viewport className={styles.rangeViewport}>
                {Object.keys(search.ranges).map((range) => (
                  <Select.Item
                    key={range}
                    value={range}
                    className={styles.rangeItem}
                  >
                    <Select.ItemText>
                      {range === "0"
                        ? t("searchbox.input.range.all")
                        : search.ranges[range]}
                    </Select.ItemText>
                    <Select.ItemIndicator />
                  </Select.Item>
                ))}
              </Select.Viewport>
            </Select.Content>
          </Select.Root>
        ) : null}
      </div>

      <LocationPermissionModal
        open={locationPermissionModalOpen}
        onOpenChange={setLocationPermissionModalOpen}
      />
    </div>
  ) : null
}

export default LocationAutocompleteInput
