import Section from "../../Section"
import styles from "./TextMediaSlider.module.scss"
import TextContent from "../../TextContent"
import TextMediaPartial from "../TextMediaPartial"
import { SwiperSlide } from "swiper/react"
import Slider from "../../Slider"
import { useEffect, useState } from "react"

export const TextMediaSlider = ({ data }) => {
  // console.log("TextMediaSlider", data)

  const [sliderID, setSliderID] = useState(null)
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
    setSliderID("tm-slider-" + Math.random().toString(36).substr(2, 9))
  }, [])

  const [showNavigation, setShowNavigation] = useState(true)

  // resize handler
  useEffect(() => {
    const handleResize = () => {
      const documentWidth = window?.document?.documentElement?.clientWidth
        ? window.document.documentElement.clientWidth
        : null

      setShowNavigation(documentWidth > 1300)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Section
      className={styles.section}
      container={false}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <div className={styles.inner}>
        <TextContent
          content={data?.textcontent}
          className={styles.textContent}
        />

        {isClient && sliderID ? (
          <Slider
            loop={false}
            autoplay={false}
            slidesPerView={1}
            showNavigation={showNavigation}
            sliderID={sliderID}
            spaceBetween={48}
          >
            {data?.slides?.map((slide, i) => (
              <SwiperSlide key={i}>
                <TextMediaPartial
                  slideIndex={i}
                  data={slide}
                  textContentHeadlineClass={styles.slideTextContentHeadline}
                  textContentToplineClass={styles.slideTextContentTopline}
                  textContentCopyClass={styles.slideTextContentCopy}
                  textContentSublineClass={styles.slideTextContentSubline}
                />
              </SwiperSlide>
            ))}
          </Slider>
        ) : null}
      </div>
    </Section>
  )
}

export default TextMediaSlider
