import styles from "./Breadcrumbs.module.scss"
import { useEffect, useState } from "react"
import useTranslation from "next-translate/useTranslation"
import { useSelector } from "react-redux"
import cx from "classnames"
import { ChevronRight } from "react-feather"
import Link from "next/link"

const Breadcrumbs = ({ className, children = [] }) => {
  const { t } = useTranslation("common")

  const pageStore = useSelector((state) => state.page)

  const [shouldRender, setShouldRender] = useState(false)
  const [parts, setParts] = useState([])
  useEffect(() => {
    setParts(pageStore?.currentPageBreadcrumbs?.filter((p) => p !== ""))
  }, [pageStore?.currentPageBreadcrumbs])

  useEffect(() => {
    setShouldRender(!(parts.length === 1 && parts[0]?.display === "Home"))
  }, [parts])

  const renderPart = (index, part, isLastPart) => (
    <span
      {...(index !== null ? { key: index } : {})}
      className={cx(styles.part, {
        [styles.last]: isLastPart,
      })}
    >
      {t(`breadcrumbs.pages.${part.display.toLowerCase()}`, null, {
        fallback: part.display,
      })}
      {!isLastPart ? <ChevronRight /> : null}
    </span>
  )

  return shouldRender ? (
    <div className={styles.Breadcrumbs}>
      <span>{t("breadcrumbs.prefix")}</span>

      {parts.map((part, index) => {
        const isLastPart = index === parts.length - 1

        return part.url ? (
          <a href={part.url} key={index}>
            {renderPart(null, part, isLastPart)}
          </a>
        ) : (
          renderPart(index, part, isLastPart)
        )
      })}
    </div>
  ) : null
}

export default Breadcrumbs
