import React, { useEffect, useRef, useState } from "react"
import * as Accordion from "@radix-ui/react-accordion"
import styles from "./FilterList.module.scss"
import * as Portal from "@radix-ui/react-portal"
import { ChevronDown, Minus, Plus, X } from "react-feather"
import useTranslation from "next-translate/useTranslation"
import CheckBox from "../../FormInputs/CheckBox"
import RadioButtons from "../../FormInputs/RadioButtons"
import RadioButton from "../../FormInputs/RadioButtons/RadioButton"
import cx from "classnames"
import Badge from "../../Badge"
import Button from "../../FormInputs/Button"
import { useSmallerThan } from "../../../lib/responsive"
import { useDispatch, useSelector } from "react-redux"
import { setActiveFilters, setTerm } from "../../../store/slices/searchSlice"
import Skeleton from "../../Skeleton"

function FilterList({
  className = "",
  accordionClassName = "",
  sectionClassName = "",
  showResultButton = false,
  resultButtonAction = () => {},
  desktopGroupCollapse = true,
}) {
  const dispatch = useDispatch()
  const search = useSelector((state) => state.search)

  const { t } = useTranslation("jobsearch")

  const isMobile = useSmallerThan("lg")

  const [filterCollapsed, setFilterCollapsed] = useState({})
  const [filterCollapsedIntialSet, setFilterCollapsedIntialSet] =
    useState(false)

  const toggleFilter = (multiple, filter, value) => {
    if (multiple) {
      if (search.activeFilters[filter]) {
        if (search.activeFilters[filter].includes(value)) {
          dispatch(
            setActiveFilters({
              ...search.activeFilters,
              [filter]: search.activeFilters[filter].filter((v) => v !== value),
            }),
          )
        } else {
          dispatch(
            setActiveFilters({
              ...search.activeFilters,
              [filter]: [...search.activeFilters[filter], value],
            }),
          )
        }
      } else {
        dispatch(
          setActiveFilters({
            ...search.activeFilters,
            [filter]: [value],
          }),
        )
      }
    } else {
      dispatch(
        setActiveFilters({
          ...search.activeFilters,
          [filter]: value,
        }),
      )
    }
  }

  const getFilterQuery = () => {
    let filterQuery = Object.keys(search.activeFilters).map((filter) => {
      const value = search.activeFilters[filter]
      if (Array.isArray(value)) {
        return value.length ? `${filter}=${value.join(",")}` : null
      } else {
        return value ? `${filter}=${value}` : null
      }
    })

    filterQuery = filterQuery.join("&")

    if (search?.term?.length) {
      filterQuery = `${filterQuery}&search=${search.term}`
    }

    return filterQuery
  }

  const [openItems, setOpenItems] = useState("")

  const [filterWereSetFromURL, setFilterWereSetFromURL] = useState(false)
  const setActiveFiltersFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search)

    const newActiveFilters = {}
    for (const [key, value] of urlParams) {
      const filter = search.filters.find((f) => f.id === key)
      if (!filter) continue

      const tryParseValue = (v) => {
        try {
          return parseInt(v)
        } catch (e) {
          return null
        }
      }

      newActiveFilters[key] = filter.multiple
        ? value
            .split(",")
            .map(tryParseValue)
            .filter((v) => v)
        : tryParseValue(value)
    }

    dispatch(setActiveFilters(newActiveFilters))

    setFilterWereSetFromURL(true)
  }

  useEffect(() => {
    setActiveFiltersFromURL()
  }, [])

  useEffect(() => {
    if (!filterWereSetFromURL) return

    const filterQuery = getFilterQuery()
    window.history.replaceState(
      null,
      null,
      `${window.location.pathname}${filterQuery ? `?${filterQuery}` : ""}`,
    )

    setOpenItems(
      search.filters
        .filter((f) => f.defaultOpen)
        .map((f) => `filter-section-${f.id}`),
    )

    if (search.filters.length && !filterCollapsedIntialSet) {
      setFilterCollapsedIntialSet(true)
      setFilterCollapsed(
        Object.values(search.filters).reduce((acc, f) => {
          acc[f.id] = true
          return acc
        }, {}),
      )
    }
  }, [search.term, search.activeFilters, filterWereSetFromURL])

  const getFilterAndOptionName = (filterName, optionId) => {
    const filter = search.filters?.find((filter) => filter.id === filterName)
    if (!filter) return null

    const filterDisplayName = t(`filter.${filter.id}.displayName`)
    let optionDisplayName = filter?.options?.find(
      (option) => option.id === optionId,
    )?.model?.name

    if (typeof optionDisplayName === "undefined") return null

    optionDisplayName = t(`filter.overrides.${optionDisplayName}`, null, {
      fallback: optionDisplayName,
    })

    return `${filterDisplayName}: ${optionDisplayName}`
  }

  const showResultButtonPortalRef = useRef(null)

  return search.filters?.length ? (
    <div className={cx(styles.FilterList, className)}>
      {Object.keys(search.activeFilters).length ? (
        <div className={styles.activeFilterList}>
          <div
            className={
              "flex flex-col-reverse flex-wrap items-start justify-between lg:flex-row lg:flex-nowrap"
            }
            ref={showResultButtonPortalRef}
          >
            <ul className={"w-full flex-shrink flex-grow lg:w-min"}>
              {Object.entries(search.activeFilters).map(([name, values]) =>
                Array.isArray(values) ? (
                  values
                    ?.filter((value) => getFilterAndOptionName(name, value))
                    ?.map((value) => (
                      <li key={`${name}-${value}`}>
                        <Badge
                          color={"red"}
                          border
                          padding={"lg"}
                          className={"pr-0"}
                        >
                          {getFilterAndOptionName(name, value)}
                          <Button
                            asStyle={"link"}
                            variant={"secondary"}
                            onClick={() => toggleFilter(true, name, value)}
                          >
                            <X />
                          </Button>
                        </Badge>
                      </li>
                    ))
                ) : (
                  <li key={`${name}-${values}`}>
                    <Badge
                      color={"red"}
                      border
                      padding={"lg"}
                      className={"pr-0"}
                    >
                      {getFilterAndOptionName(name, values)}
                      <Button
                        asStyle={"link"}
                        variant={"secondary"}
                        onClick={() => toggleFilter(true, name, value)}
                      >
                        <X />
                      </Button>
                    </Badge>
                  </li>
                ),
              )}
            </ul>
          </div>

          <div
            className={cx("flex w-full items-center", {
              "justify-between": Object.keys(search.activeFilters).length > 0,
              "justify-end": Object.keys(search.activeFilters).length === 0,
            })}
          >
            {Object.keys(search.activeFilters).filter(
              (f) => ["city", "seafrch"].indexOf(f) === -1,
            ).length > 0 ? (
              <Button
                asStyle={"link"}
                variant={"tertiary"}
                onClick={() => {
                  dispatch(setActiveFilters({}))
                }}
                className={"pl-0"}
              >
                {t("filterModal.removeAll")} <X />
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}

      <Portal.Root container={showResultButtonPortalRef.current}>
        {showResultButton ? (
          <Button
            className={"mb-10 w-full flex-shrink-0 flex-grow lg:mb-0 lg:w-fit"}
            variant={"primary"}
            onClick={() => {
              resultButtonAction()
            }}
          >
            {t("filterModal.showResults")}
          </Button>
        ) : null}
      </Portal.Root>

      <Accordion.Root
        className={cx(styles.accordion, accordionClassName)}
        type="multiple"
        collapsible={"collapsible"}
        value={openItems}
        onValueChange={setOpenItems}
      >
        {search.filters
          .filter((filter) => {
            const ignoreFilters = ["contractPeriods"]
            return ignoreFilters.indexOf(filter.id) === -1
          })
          .map((filter) => {
            const key = `filter-section-${filter.id}`
            const isCollapsed = filterCollapsed[filter.id]

            return (
              <Accordion.Item
                key={key}
                className={cx(styles.section, sectionClassName)}
                value={key}
              >
                <Accordion.Header className={"mb-4"}>
                  <Accordion.Trigger
                    className={styles.accordionTrigger}
                    disabled={!isMobile && !desktopGroupCollapse}
                  >
                    <span className={"text-base font-bold"}>
                      {t(`filter.${filter.id}.displayName`)}
                    </span>

                    {desktopGroupCollapse || isMobile ? (
                      <ChevronDown aria-hidden />
                    ) : null}
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content>
                  <ul>
                    {filter.multiple ? (
                      [...filter.options]
                        .sort((a, b) => {
                          if (search.activeFilters[filter.id]?.includes(a.id))
                            return -1
                          if (search.activeFilters[filter.id]?.includes(b.id))
                            return 1
                          return 0
                        })
                        .map((option, index) => {
                          const key = `filter-${filter.id}-${option.id}`

                          if (isCollapsed && index >= filter.maxItems)
                            return null

                          return (
                            <li key={key} className={"mb-2"}>
                              <CheckBox
                                data-key={`cb-${key}`}
                                key={`cb-${key}`}
                                cKey={`cb-${key}`}
                                onCheckedChange={(value) => {
                                  toggleFilter(
                                    filter.multiple,
                                    filter.id,
                                    option.id,
                                  )
                                }}
                                checked={
                                  search.activeFilters[filter.id]?.includes(
                                    option.id,
                                  ) || false
                                }
                                boxClassName={"w-5 h-5"}
                              >
                                <div
                                  className={
                                    "relative flex w-full items-center gap-2"
                                  }
                                >
                                  {search?.isLoading ? (
                                    <Skeleton width={"150px"} height={22} />
                                  ) : (
                                    <>
                                      <span className={"text-base"}>
                                        {t(
                                          `filter.overrides.${option.model.name}`,
                                          null,
                                          {
                                            fallback: option.model.name,
                                          },
                                        )}
                                      </span>
                                      <Badge color={"red"} circle>
                                        {option.count}
                                      </Badge>
                                    </>
                                  )}
                                </div>
                              </CheckBox>
                            </li>
                          )
                        })
                    ) : (
                      <RadioButtons
                        cKey={`filter-${filter.id}`}
                        onValueChange={(value) => {
                          toggleFilter(filter.multiple, filter.id, value)
                        }}
                        value={search.activeFilters?.[filter.id] || null}
                      >
                        {filter.options.map((option) => {
                          const key = `filter-${filter.id}-${option.id}`
                          return (
                            <RadioButton
                              value={option.id}
                              data-key={key}
                              key={key}
                              cKey={key}
                              className={"mb-2"}
                              radioClassName={"w-5 h-5"}
                            >
                              <div className={"flex items-center gap-2"}>
                                {search?.isLoading ? (
                                  <Skeleton width={"150px"} height={22} />
                                ) : (
                                  <>
                                    <span className={"text-base"}>
                                      {t(
                                        `filter.overrides.${option.model.name}`,
                                        null,
                                        {
                                          fallback: option.model.name,
                                        },
                                      )}
                                    </span>{" "}
                                    <Badge color={"red"} circle>
                                      {option.count}
                                    </Badge>
                                  </>
                                )}
                              </div>
                            </RadioButton>
                          )
                        })}
                      </RadioButtons>
                    )}
                  </ul>

                  {filter.options.length > filter.maxItems ? (
                    <Button
                      variant={"tertiary"}
                      className={"mt-4 pl-0"}
                      asStyle={"link"}
                      iconLeft={isCollapsed ? <Plus /> : <Minus />}
                      onClick={() => {
                        setFilterCollapsed({
                          ...filterCollapsed,
                          [filter.id]: !isCollapsed,
                        })
                      }}
                    >
                      {t(`filterItems.show${isCollapsed ? "More" : "Less"}`)}
                    </Button>
                  ) : null}
                </Accordion.Content>
              </Accordion.Item>
            )
          })}
      </Accordion.Root>
    </div>
  ) : null
}

export default FilterList
