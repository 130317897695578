import ReactPaginate from "react-paginate"
import styles from "./Pagination.module.scss"
import cx from "classnames"
import { ChevronLeft, ChevronRight } from "react-feather"
import { useSelector } from "react-redux"

const Pagination = ({ currentPage = 1, className, ...rootProps }) => {
  // console.log(paginationInformation)

  const search = useSelector((state) => state.search)

  return (
    <ReactPaginate
      className={cx(styles.Pagination, className)}
      pageLinkClassName={styles.link}
      previousLinkClassName={styles.link}
      nextLinkClassName={styles.link}
      activeLinkClassName={styles.active}
      breakLabel="..."
      previousLabel={<ChevronLeft />}
      nextLabel={<ChevronRight />}
      pageRangeDisplayed={5}
      marginPagesDisplayed={2}
      pageCount={parseInt(search?.meta?.totalPages) || 0}
      renderOnZeroPageCount={null}
      forcePage={currentPage - 1}
      {...rootProps}
    />
  )
}

export default Pagination
