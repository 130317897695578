import Section from "../Section"
import TextMediaPartial from "./TextMediaPartial"

export const TextMedia = ({ data }) => {
  // console.log("TextMedia", data)

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <TextMediaPartial data={data} />
    </Section>
  )
}

export default TextMedia
