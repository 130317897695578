import React from "react"
import * as RadioGroup from "@radix-ui/react-radio-group"
import { uuid } from "../../../lib/util"
import styles from "../CheckBox/CheckBox.module.scss"

function RadioButtons({ children = [], cKey = "", ...rootProps }) {
  const id = cKey || `c-${uuid()}`

  return (
    <RadioGroup.Root
      id={id}
      className={styles.RadioButtons}
      aria-labelledby={`lbl-${id}`}
      {...rootProps}
    >
      {children}
    </RadioGroup.Root>
  )
}

export default RadioButtons
