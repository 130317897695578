import { Swiper } from "swiper/react"
import { Autoplay, Keyboard, Navigation, Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/pagination"
import { useEffect, useRef, useState } from "react"
import { ChevronLeft, ChevronRight } from "react-feather"
import styles from "./Slider.module.scss"
import cx from "classnames"

function Slider({
  children = {},
  slidesPerView = "auto",
  loop = true,
  spaceBetween = 0,
  autoplay = false,
  autoplaySpeed = 5000,
  breakpoints = {},
  showNavigation = true,
  sliderID = null,
  rootProps = {},
}) {
  const swiperRef = useRef(null)
  const swiperPagination = useRef(null)
  const swiperPaginationButtons = useRef(null)
  const [bulletWidth, setBulletWidth] = useState(20)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const [swiperNextHidden, setSwiperNextHidden] = useState(false)
  const [swiperPrevHidden, setSwiperPrevHidden] = useState(false)

  const updateBulletWidth = () => {
    if (!swiperPagination?.current) return

    const bulletSpacing = 8
    if ((children?.length || 0) === 0) return

    const newBulletWidth =
      swiperPagination.current.getBoundingClientRect().width /
        (children?.length || 1) -
      bulletSpacing -
      Math.ceil(18 / (children?.length || 1))

    setBulletWidth(newBulletWidth)
  }

  useEffect(() => {
    if (!swiperPagination?.current) return

    swiperPagination.current
      .querySelectorAll(".swiper-pagination-custom > div")
      .forEach((bullet, i) => {
        bullet.style.width = `${bulletWidth}px`
      })
  }, [activeSlideIndex, bulletWidth])

  useEffect(() => {
    if (
      !swiperPagination?.current ||
      !swiperRef?.current ||
      !swiperPaginationButtons?.current
    ) {
      return
    }

    updateBulletWidth()
  }, [
    swiperPagination?.current,
    swiperRef?.current,
    swiperPaginationButtons?.current,
  ])

  useEffect(() => {
    if (!swiperPagination?.current) return

    const observer = new ResizeObserver(() => {
      updateBulletWidth()
    })
    observer.observe(swiperPagination.current)
  }, [swiperPagination?.current])

  const toggleNavigationButtons = (swiper) => {
    setSwiperNextHidden(false)
    setSwiperPrevHidden(false)

    if (swiper.isBeginning) {
      setSwiperPrevHidden(true)
    } else if (swiper.isEnd) {
      setSwiperNextHidden(true)
    }
  }
  const preloadSlides = (swiper) => {
    swiper.slides
      ?.map((slide) => slide.querySelectorAll("img")[0])
      ?.forEach((s) => {
        s?.setAttribute("loading", "eager")
        s?.setAttribute("data-loading", "eager")
      })
  }
  return (
    <div className={styles.Slider}>
      <Swiper
        ref={swiperRef}
        className={`swiper-pagination-outside`}
        pagination={{
          enabled: true,
          clickable: true,
          el: swiperPagination?.current,
          renderBullet: function (index, className) {
            return `<div data-index=${index} class="${className}"></div>`
          },
        }}
        onSlideChange={(swiper) => {
          preloadSlides(swiper)
          setActiveSlideIndex(swiper.realIndex)
        }}
        onInit={(swiper) => {
          preloadSlides(swiper)
          updateBulletWidth()
          toggleNavigationButtons(swiper)
        }}
        onBreakpoint={(swiper) => {
          updateBulletWidth()
          toggleNavigationButtons(swiper)
        }}
        onActiveIndexChange={(swiper) => {
          updateBulletWidth()
          toggleNavigationButtons(swiper)
        }}
        keyboard={{
          enabled: true,
          pageUpDown: false,
        }}
        navigation={{
          enabled: true,
          nextEl: `.swiper-button-next-${sliderID}`,
          prevEl: `.swiper-button-prev-${sliderID}`,
        }}
        modules={[Pagination, Navigation, Keyboard, Autoplay]}
        slidesPerView={slidesPerView}
        loop={loop}
        spaceBetween={spaceBetween}
        autoHeight={true}
        breakpoints={breakpoints}
        autoplay={
          autoplay
            ? {
                delay: autoplaySpeed,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
                waitForTransition: true,
              }
            : false
        }
        {...rootProps}
      >
        {children}
      </Swiper>

      <div className={"swiper-pagination-wrapper"}>
        <div
          ref={swiperPagination}
          className="swiper-pagination-custom"
          data-bullet={`${bulletWidth}px`}
        />
      </div>

      {showNavigation && sliderID ? (
        <>
          <div
            className={cx(
              styles.swiperButtonPrev,
              `swiper-button-prev-${sliderID}`,
              {
                hidden: swiperPrevHidden,
              },
            )}
          >
            <ChevronLeft size={48} />
          </div>

          <div
            className={cx(
              styles.swiperButtonNext,
              `swiper-button-next-${sliderID}`,
              {
                hidden: swiperNextHidden,
              },
            )}
          >
            <ChevronRight size={48} />
          </div>
        </>
      ) : null}
    </div>
  )
}

export default Slider
