import * as Dialog from "@radix-ui/react-dialog"
import styles from "./LocationPermissionModal.module.scss"
import Button from "../../FormInputs/Button"
import Link from "next/link"
import { X } from "react-feather"
import React from "react"
import useTranslation from "next-translate/useTranslation"

const LocationPermissionModal = ({ open, onOpenChange }) => {
  const { t } = useTranslation("jobsearch")

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal container={document.getElementById("__next")}>
        <Dialog.Overlay className={styles.dialogOverlay} />
        <Dialog.Content className={styles.dialogContent}>
          <div className={styles.wrapper}>
            <div className={styles.dialogContentTitle}>
              {t("locationAutocomplete.detection.fail.title")}
            </div>
            <div className={styles.dialogContentText}>
              {t("locationAutocomplete.detection.fail.text")}
            </div>

            <div className={styles.dialogContentLinks}>
              <div className={"mb-2 font-bold"}>
                {t("locationAutocomplete.detection.fail.links")}
              </div>

              <div
                className={
                  "mt-4 flex flex-col flex-wrap items-center gap-4 md:flex-row"
                }
              >
                <Button variant={"secondary"} as={"div"}>
                  <a
                    href={
                      "https://support.google.com/chrome/answer/142065?hl=de"
                    }
                    target={"_blank"}
                    rel="nofollow noreferrer"
                  >
                    Google Chrome{" "}
                  </a>
                </Button>
                <Button variant={"secondary"} as={"div"}>
                  <a
                    href={
                      "https://support.mozilla.org/de/kb/gibt-firefox-meinen-standort-websites-frei"
                    }
                    target={"_blank"}
                    rel="nofollow noreferrer"
                  >
                    Mozilla Firefox{" "}
                  </a>
                </Button>
                <Button variant={"secondary"} as={"div"}>
                  <a
                    href={
                      "https://support.microsoft.com/de-de/topic/freigeben-ihres-standorts-auf-microsoft-edge-ceb2e27a-05a8-4ab3-a087-fefb969ded65"
                    }
                    target={"_blank"}
                    rel="nofollow noreferrer"
                  >
                    Microsoft Edge{" "}
                  </a>
                </Button>
              </div>
            </div>

            <Dialog.Close className={styles.buttonWrapper} asChild={"div"}>
              <Button variant={"primary"} iconRight={<X />}>
                {t("locationAutocomplete.detection.fail.closeModal")}
              </Button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default LocationPermissionModal
