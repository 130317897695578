import styles from "./Facts.module.scss"
import Section from "../Section"
import TextContent from "../TextContent"
import cx from "classnames"
import CountUp from "react-countup"
import Image from "../Image"
import VisibilitySensor from "react-visibility-sensor"

export const Facts = ({ data }) => {
  // console.log("Facts", data)

  return (
    <Section
      className={cx(styles.section)}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      {data?.textcontent?.headline ? (
        <div className={styles.textContent}>
          <TextContent content={data?.textcontent} />
        </div>
      ) : null}

      <div className={styles.facts}>
        {data?.facts?.map((fact, i) => (
          <div
            key={i}
            className={cx(styles.fact, {
              [styles.noBg]: !fact?.background_image,
            })}
          >
            {fact?.background_image ? (
              <div className={styles.imageWrapper}>
                <Image
                  alt={fact?.background_image?.alt}
                  src={fact?.background_image?.permalink}
                  fill
                  sizes="(max-width: 767px) 100vw, 50vw"
                  style={{
                    objectPosition: fact?.background_image?.focus_css,
                    objectFit: "cover",
                  }}
                />
              </div>
            ) : null}

            <div className={styles.content}>
              {fact?.number ? (
                <CountUp
                  decimal={null}
                  separator={fact?.thousands_separator ? "." : ""}
                  start={parseInt(fact?.count_start)}
                  end={parseInt(fact?.number)}
                  prefix={(fact?.prefix ?? "") + " "}
                  suffix={
                    " " + (fact?.plus_sign ? "+" : "") + (fact?.postfix ?? "")
                  }
                >
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start}>
                      <span
                        className={cx(styles.number, {
                          [styles.red]: fact?.number_color?.value === "red",
                          [styles.white]: fact?.number_color?.value === "white",
                        })}
                        ref={countUpRef ?? null}
                      />
                    </VisibilitySensor>
                  )}
                </CountUp>
              ) : null}

              {fact?.text ? (
                <div
                  className={cx(styles.text, {
                    [styles.red]: fact?.text_color?.value === "red",
                    [styles.white]: fact?.text_color?.value === "white",
                  })}
                  dangerouslySetInnerHTML={{ __html: fact?.text }}
                />
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}

export default Facts
