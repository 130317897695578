import styles from "./Card.module.scss"
import cx from "classnames"
import Image from "../Image"

export const Card = ({
  image = null,
  smallPadding = true,
  minHeight = "auto",
  heightImage = "auto",
  className = "",
  link = null,
  children = [],
}) => {
  // console.log("Card", link)

  const ContentEL = (
    <div
      className={cx(styles.Card, className)}
      style={{
        minHeight: minHeight,
      }}
    >
      {image?.permalink ? (
        <div
          className={styles.image}
          style={{
            height: heightImage,
          }}
        >
          <Image
            alt={image?.alt}
            src={image?.permalink}
            fill
            style={{
              objectPosition: image?.focus_css,
              objectFit: "cover",
            }}
            sizes="(max-width: 767px) 486px, (max-width: 1919px) 370px, 486px"
          />
        </div>
      ) : null}

      <div
        className={cx(styles.contentWrapper, {
          [styles.smallPadding]: smallPadding,
        })}
      >
        {children}
      </div>
    </div>
  )

  return link ? <a href={link}>{ContentEL}</a> : ContentEL
}

export default Card
