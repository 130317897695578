import styles from "./TextContent.module.scss"
import cx from "classnames"
import Button from "../FormInputs/Button"
import { stripHtml } from "../../lib/util"

// import CTA from "../CTA"

function TextContent({
  content = {},
  className = "",
  toplineClassName = "",
  headlineClassName = "",
  sublineClassName = "",
  copyClassName = "",
  buttonAlignment = "left",
  style = {},
}) {
  if (!content) return null

  // console.log(content)

  if (Array.isArray(content) && content.length > 0) {
    content = content[0]
  }

  // prevent undefined errors, since we're using dynamic classnames
  toplineClassName
  headlineClassName
  sublineClassName
  copyClassName

  return (content?.headline && content?.headline?.length) ||
    (content?.topline && content?.topline?.length) ||
    (content?.subline && content?.subline?.length) ||
    (content?.copy && content?.copy?.length) ||
    content?.buttons?.length ? (
    <div style={style} className={cx(styles.TextContent, className)}>
      {["topline", "headline", "subline", "copy"].map((key) => {
        const element = content?.[key]
        if (!element || !element?.length) return null

        let HTML_TAG = content?.[`${key}_html_tag`]?.value || "default"
        if (HTML_TAG === "default") {
          HTML_TAG =
            key === "headline" ? "h2" : key === "subline" ? "h3" : "div"
        }

        const size = content?.[`${key}_size`]?.value || "default"

        return key !== "copy" ? (
          <HTML_TAG
            key={key}
            className={cx(styles[key], eval(`${key}ClassName`), {
              [styles[`size-${size}`]]: size && size !== "default",
            })}
            suppressHydrationWarning={true}
            dangerouslySetInnerHTML={{ __html: stripHtml(content?.[key]) }}
          />
        ) : (
          <HTML_TAG
            key={key}
            className={cx(styles.copy, copyClassName, {
              [styles[`size-copy`]]: size && size !== "default",
              [styles[`columns-${content?.copy_columns?.value}`]]: true,
            })}
          >
            {content?.copy?.map((copySection, i) => {
              const isText = copySection.__typename === "BardText"
              const isHeadline =
                copySection.__typename === "Set_Textcontent_Copy_Components"

              let HEADLINE_HTML_TAG =
                copySection?.headline_html_tag?.value || "default"
              if (HEADLINE_HTML_TAG === "default") {
                HEADLINE_HTML_TAG = "h4"
              }

              const headlineSize =
                copySection?.headline_size?.value || "default"

              return isText ? (
                <div
                  key={i}
                  dangerouslySetInnerHTML={{ __html: copySection?.text || "" }}
                  suppressHydrationWarning={true}
                />
              ) : isHeadline ? (
                <HEADLINE_HTML_TAG
                  key={i}
                  className={cx(styles.copyHeadline, headlineClassName, {
                    [styles[`headline-size-${headlineSize}`]]:
                      headlineSize && headlineSize !== "default",
                  })}
                  dangerouslySetInnerHTML={{
                    __html: copySection?.headline || "",
                  }}
                />
              ) : (
                <></>
              )
            })}
          </HTML_TAG>
        )
      })}

      {content?.buttons?.[0]?.button?.length ? (
        <div
          className={styles.buttons}
          style={{
            justifyContent:
              buttonAlignment === "center"
                ? "center"
                : buttonAlignment === "left"
                ? "start"
                : "end",
          }}
        >
          {content?.buttons?.[0]?.button?.map(
            ({ link, display_type, asset, icon, label, target }, i) => (
              <a href={link || "#"} target={target?.value || "_self"} key={i}>
                <Button
                  key={i}
                  variant={
                    i === content.buttons.length - 1 ? "primary" : "secondary"
                  }
                  asStyle={
                    display_type?.value?.startsWith("button")
                      ? "button"
                      : "link"
                  }
                  iconLeft={icon}
                >
                  {label}
                </Button>
              </a>
            ),
          )}
        </div>
      ) : null}
    </div>
  ) : null
}

export default TextContent
