import styles from "./Benefits.module.scss"
import Section from "../Section"
import "swiper/css"
import "swiper/css/effect-fade"
import cx from "classnames"
import TextContent from "../TextContent"
import useTranslation from "next-translate/useTranslation"
import Button from "../FormInputs/Button"
import FontAwesomeIconHydrationSafe from "../FontAwesomeIconHydrationSafe"

export const Benefits = ({ data }) => {
  // console.log("Benefits", data)

  const { t } = useTranslation("common")

  return (
    <Section
      className={cx(styles.Benefits, {
        [styles.green]: data?.style?.value === "green",
        [styles.white]: data?.style?.value === "white",
        [styles.greyLight]: data?.style?.value === "grey-light",
        [styles.greyDark]: data?.style?.value === "grey-dark",
      })}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      container={false}
    >
      <div className={styles.inner}>
        <TextContent
          content={data?.textcontent}
          className={styles.textContent}
        />

        <div className={styles.benefitsGrid}>
          {data?.benefits?.map((benefit, i) => (
            <div className={styles.benefit} key={i}>
              {!benefit?.fa_icon && benefit?.image ? (
                <div
                  className={styles.benefitImage}
                  dangerouslySetInnerHTML={{ __html: benefit?.image }}
                />
              ) : null}

              {benefit?.fa_icon ? (
                <FontAwesomeIconHydrationSafe
                  className={styles.benefitImage}
                  icon={benefit.fa_icon}
                />
              ) : null}

              {benefit?.headline ? (
                <div
                  className={styles.headline}
                  dangerouslySetInnerHTML={{ __html: benefit?.headline }}
                />
              ) : null}

              {benefit?.copy ? (
                <div
                  className={styles.copy}
                  dangerouslySetInnerHTML={{ __html: benefit?.copy }}
                />
              ) : null}
            </div>
          ))}
        </div>

        {data?.button?.[0] ? (
          <div className={styles.buttonWrapper}>
            <a
              href={data?.button?.[0].link || "#"}
              target={data?.button?.[0]?.target?.value || "_self"}
            >
              <Button
                variant={
                  data?.style?.value === "green" ? "secondary" : "primary"
                }
                className={cx("mt-12", {
                  "border-none": data?.style?.value === "green",
                })}
              >
                {data?.button?.[0].label}
              </Button>
            </a>
          </div>
        ) : null}
      </div>
    </Section>
  )
}

export default Benefits
