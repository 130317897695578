import Card from "../Card"
import Section from "../Section"
import TextContent from "../TextContent"
import styles from "./ContactPersons.module.scss"
import { Phone } from "react-feather"
import { useEffect, useState } from "react"

function ContactPersons({ data = {} }) {
  // console.log("ContactPersons", data)

  const [groupedPersons, setGroupedPersons] = useState([])
  useEffect(() => {
    if (data?.group_by_category) {
      setGroupedPersons(
        data?.contact_persons?.reduce((acc, person) => {
          person?.category?.forEach((category) => {
            if (!acc[category.value]) {
              acc[category.value] = []
            }
            acc[category.value].push(person)
          })

          return acc
        }, {}),
      )
    }
  }, [data?.contact_persons])

  const renderPersons = (gPersons = []) => {
    const persons = data?.group_by_category ? gPersons : data?.contact_persons

    return (
      <div className={styles.persons}>
        {persons?.map((person, i) => (
          <div key={i} className={styles.person}>
            <Card minHeight={368} image={person?.image} heightImage={200}>
              {person?.title ? (
                <div
                  className={styles.title}
                  dangerouslySetInnerHTML={{ __html: person.title }}
                ></div>
              ) : null}

              {person?.position ? (
                <div
                  className={styles.position}
                  dangerouslySetInnerHTML={{ __html: person.position }}
                ></div>
              ) : null}

              {person?.phone ? (
                <div className={styles.phone}>
                  <a href={`tel:${person.phone?.replaceAll(/[\s+-]/g, "")}`}>
                    <Phone />
                    {person.phone}
                  </a>
                </div>
              ) : null}
            </Card>
          </div>
        ))}
      </div>
    )
  }

  return (
    <Section
      className={styles.ContactPersons}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <TextContent content={data?.textcontent} className={styles.textContent} />

      {data?.group_by_category ? (
        <div className={styles.contactGroups}>
          {Object.entries(groupedPersons)?.map(([group, persons], i) => (
            <div className={styles.contactGroup} key={i}>
              <div className={styles.groupTitle}>{group}</div>

              {renderPersons(persons)}
            </div>
          ))}
        </div>
      ) : (
        renderPersons()
      )}
    </Section>
  )
}

export default ContactPersons
