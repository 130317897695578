import styles from "./RecommendedJobsSlider.module.scss"
import Section from "../Section"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Navigation, Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/effect-fade"
import cx from "classnames"
import TextContent from "../TextContent"
import { ChevronLeft, ChevronRight } from "react-feather"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import JobCard from "../JobSearch/JobCard"
import Button from "../FormInputs/Button"
import axios from "axios"

import getConfig from "next/config"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { forwardGeo } from "../../lib/reverse-geocoding"

const {
  publicRuntimeConfig: { API_URL, GOOGLE_MAPS_API_KEY },
} = getConfig()

export const RecommendedJobsSlider = ({ data }) => {
  // console.log("RecommendedJobsSlider", data)

  const [sliderID, setSliderID] = useState(null)
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
    setSliderID("rc-slider-" + Math.random().toString(36).substr(2, 9))
  }, [])

  const search = useSelector((state) => state.search)

  const [resultsRecommended, setResultsRecommended] = useState([])

  const [filterLocation, setFilterLocation] = useState(null)
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: GOOGLE_MAPS_API_KEY,
      options: {
        types: ["(regions)"],
        componentRestrictions: { country: ["DE"] },
        debounce: 300,
        language: "de",
      },
    })

  useEffect(() => {
    const prediction = placePredictions?.[0]
    if (prediction) {
      placesService.getDetails(
        {
          placeId: prediction.place_id,
        },
        (placeDetails) => {
          forwardGeo(placeDetails.place_id).then((response) => {
            setFilterLocation(
              {
                lat: response.geometry.location.lat(),
                lng: response.geometry.location.lng(),
                range: 20,
              } || null,
            )
          })
        },
      )
    }
  }, [placePredictions])

  const searchRecommendedJobs = async ({ term, fixedLocation }, thunkAPI) => {
    let searchParams = {
      term,
      filters: [],
      location: fixedLocation
        ? fixedLocation
        : search?.location?.city
          ? search.location
          : null,
      sort:
        fixedLocation || search?.location?.city
          ? "distance"
          : term
            ? "date"
            : "random",
    }

    const data = await axios
      .post(`${API_URL}/api/jobs/search?type=jobs&page=1&rc=1`, {
        search: searchParams,
      })
      .catch(function (err) {})

    setResultsRecommended(data?.data?.results || [])
  }

  useEffect(() => {
    if (data?.filter_location?.length) {
      setResultsRecommended([])
      getPlacePredictions({ input: data?.filter_location })
      return
    }

    if (!resultsRecommended?.length) {
      searchRecommendedJobs({
        term: data?.filter_searchterm || null,
      })
    }
  }, [])

  useEffect(() => {
    if (data?.filter_location?.length && !filterLocation) {
      return
    }

    searchRecommendedJobs({
      term: data?.filter_searchterm || null,
      fixedLocation: filterLocation || null,
    })

    setLocationAvailable(!!filterLocation || !!search?.location?.city)
  }, [filterLocation, search?.location?.city])

  const [locationAvailable, setLocationAvailable] = useState(false)
  const [swiperNextHidden, setSwiperNextHidden] = useState(false)
  const [swiperPrevHidden, setSwiperPrevHidden] = useState(false)

  const toggleNavigationButtons = (swiper) => {
    if (resultsRecommended?.length <= swiper.params.slidesPerView) {
      setSwiperNextHidden(true)
      setSwiperPrevHidden(true)
    } else {
      setSwiperNextHidden(false)
      setSwiperPrevHidden(false)

      if (swiper.isBeginning) {
        setSwiperPrevHidden(true)
      } else if (swiper.isEnd) {
        setSwiperNextHidden(true)
      }
    }
  }

  return isClient &&
    sliderID &&
    (data?.filter_location?.length ? data?.filter_location?.length : true) &&
    resultsRecommended?.length > 0 ? (
    <Section
      className={cx(styles.RecommendedJobsSlider)}
      container={false}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <div className={styles.inner}>
        <TextContent
          content={{
            headline: locationAvailable
              ? data?.headline_location_available
              : data?.headline_location_unavailable,
          }}
          className={styles.textContent}
        />

        <div className={styles.swiperContainer}>
          <Swiper
            onInit={(swiper) => {
              toggleNavigationButtons(swiper)
            }}
            onBreakpoint={(swiper) => {
              toggleNavigationButtons(swiper)
            }}
            onActiveIndexChange={(swiper) => {
              toggleNavigationButtons(swiper)
            }}
            pagination={{
              el: `.recommend-swiper-pagination-${sliderID}`,
              enabled: true,
              clickable: true,
              bulletActiveClass: styles.bulletActive,
              renderBullet: (index, className) => {
                return `<div
                  data-index="${index}"
                  class="${cx(className, styles.bullet)}"
                ></div>`
              },
            }}
            navigation={{
              nextEl: `.recommend-swiper-button-next-${sliderID}`,
              prevEl: `.recommend-swiper-button-prev-${sliderID}`,
            }}
            slidesPerView={3}
            className={styles.swiper}
            autoHeight={true}
            modules={[Autoplay, Pagination, Navigation]}
            autoplay={false}
            loop={false}
            spaceBetween={24}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {resultsRecommended?.length
              ? resultsRecommended?.map((job, i) => (
                  <SwiperSlide key={i}>
                    <JobCard job={job} className={"min-h-[310px]"} />
                  </SwiperSlide>
                ))
              : null}
          </Swiper>

          <div
            className={cx(
              `recommend-swiper-pagination-${sliderID}`,
              styles.swiperPagination,
            )}
          />

          <div
            className={cx(
              styles.swiperNavNext,
              `recommend-swiper-button-next-${sliderID}`,
              {
                hidden: swiperNextHidden,
              },
            )}
          >
            <ChevronRight size={24} />
          </div>
          <div
            className={cx(
              styles.swiperNavPrev,
              `recommend-swiper-button-prev-${sliderID}`,
              {
                hidden: swiperPrevHidden,
              },
            )}
          >
            <ChevronLeft size={24} />
          </div>
        </div>

        {data?.button?.[0] ? (
          <div className={styles.buttonWrapper}>
            <a
              href={data?.button?.[0].link || "#"}
              target={data?.button?.[0]?.target?.value || "_self"}
            >
              <Button variant={"primary"}>{data?.button?.[0].label}</Button>
            </a>
          </div>
        ) : null}
      </div>
    </Section>
  ) : null
}

export default RecommendedJobsSlider
