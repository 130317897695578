/*
    Wrapper for FontAwesome icons to prevent ui hydration errors
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import cx from "classnames"

const FontAwesomeIconHydrationSafe = ({
  icon,
  responsive = true,
  className = "",
  ...props
}) => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return isClient ? (
    <FontAwesomeIcon className={cx(className)} icon={icon} {...props} />
  ) : null
}

export default FontAwesomeIconHydrationSafe
