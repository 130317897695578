import React from "react"
import * as Form from "@radix-ui/react-form"
import styles from "./Input.module.scss"
import useTranslation from "next-translate/useTranslation"
import { X } from "react-feather"
import cx from "classnames"

function Input({
  className,
  label = "",
  errorText = { required: null, invalid: null },
  cKey = "",
  value,
  setValue,
  clearable = false,
  ...rootProps
}) {
  const { t } = useTranslation("common")

  return (
    <Form.Field className={styles.InputFieldWrapper} {...rootProps}>
      <div className={styles.InputField}>
        <Form.Label className={styles.label}>{label}</Form.Label>
      </div>
      <div className={styles.InputWrapper}>
        <Form.Control asChild>
          <input
            className={cx(styles.Input, className)}
            type={rootProps.type}
            required={rootProps.required}
            placeholder={rootProps.placeholder}
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
            }}
          />
        </Form.Control>

        {value && clearable ? (
          <div
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setValue("")
            }}
            className={styles.clearButton}
            role={"button"}
            aria-label={t("common:form.aria.clear")}
          >
            <X />
          </div>
        ) : null}
      </div>
      <div className={styles.errorMessageWrapper}>
        {rootProps?.required ? (
          <Form.Message className="FormMessage" match="valueMissing">
            {errorText?.required || t("common:form.error.required")}
          </Form.Message>
        ) : null}
        <Form.Message className="FormMessage" match="typeMismatch">
          {errorText?.invalid || t("common:form.error.invalid")}
        </Form.Message>
      </div>
    </Form.Field>
  )
}

export default Input
