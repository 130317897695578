import cx from "classnames"
import Section from "../Section"
import TextContent from "../TextContent"
import styles from "./MultiColumnText.module.scss"

export const MultiColumnText = ({ data }) => {
  // console.log("MultiColumnText", data)

  return (
    <Section
      className={styles.section}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <TextContent content={data?.textcontent} className={styles.textContent} />

      <div className={styles.columns}>
        {data?.columns?.map((column, index) => {
          return (
            <div
              key={index}
              className={cx(styles.column, {
                [styles[`size-${column.width?.value ?? 50}`]]:
                  column.width?.value,
              })}
            >
              <TextContent
                content={column?.textcontent}
                className={styles.textContent}
              />
            </div>
          )
        })}
      </div>
    </Section>
  )
}

export default MultiColumnText
